<template>

	<div>

	
	<Header
		title="Die Flesch-Formel"
		subtitle="Gelesen ist nicht verstanden."
	></Header>
		
	
  	<v-container fluid white >
  	<v-container appwidth class="py-15" black--text>
		<v-row>		
			<v-col cols="12" sm="12" md="6" lg="6">

				<p class="text-md-body-1">Ob ein Text lesbar ist entscheidet darüber, ob der Leser dessen Inhalt aufnimmt. Anders als im direkten Dialog, kann der Verfasser eines Textes den Leser nicht bei der Lektüre begleiten, auf Missverständnisse reagieren. Erreicht die geschriebene Botschaft den Empfänger?</P>

				<p class="text-md-body-1">Der Wiener Rudolf Flesch hat den Flesch-Index entwickelt. Er hilft einzuschätzen, wie eingängig ein Text ist. Der Flesch-Index basiert auf einer Formel. In sie fließen die Anzahl der Sätze, Wörter und Silben ein. Durchläuft der gewählte Text die Formel, so steht am Ende ein Wert (flesch reading ease score) zwischen 0 und 100; in Extremfällen kann sich ein Negativwert ergeben. Je höher der errechnete Wert, desto verständlicher ist der Text. Der Index bewertet das Geschriebene nicht. Auch für völlig sinnenentleerte Texte lässt er sich berechnen. Es geht nur um die generelle Lesbarkeit. Der Flesch-Index kategorisiert einen Text dahingehend, dass er den verschiedenen Schreibstilen zugeordnet werden kann, bspw. der wissenschaftlichen Abhandlung (geringer Index) oder dem Comic (hoher Index).</p>


			</v-col>
			
			<v-col cols="12" offset-md="1" sm="12" md="5" lg="5">
				<p class="text-md-body-1">Für die deutsche Sprache wird folgende Formel verwendet:</p>
				<p class="font-weight-black">Flesch-Wert = 180 - ASL - (58,5 * ASW)</p>

				<p class="text-md-body-1">Die durchschnittliche Satzlänge (ASL - Average Sentence Length) ist die Anzahl der Worte im Text geteilt durch die Anzahl der Sätze des Textes. Die durchschnittliche Silbenanzahl pro Wort (ASW - Average Number of Syllables per Word) ist die Silbenanzahl des gesamten Textes geteilt durch die Anzahl der Worte im Text.</p>

				<h2 class="text-md-h5 pb-4">Einteilung</h2>
				<div class="font-weight-black" v-for="item in desserts" :key="item.name">
				  {{ item.wert }} - {{ item.lesbarkeit }}
				</div>
			</v-col>			
			
		</v-row>
				
	</v-container>
	</v-container>
	</div>
		
</template>

<script>


import Header from '@/components/Header';
export default {
  
		components: {
			Header,
		},

  
	name: 'Formel',
  
  
  	head: {
		title: {
			inner: 'Formel',
			separator: '-',
			complement: 'Fleschindex'
		},
  
		link: [
			{
				rel: 'canonical',
				href: 'https://fleschindex.de/formel/',
			}
		],
		meta: [
			{
				name: 'description',
				content: 'Erläuterungen zur Flesch-Index Berechnung',
			},
			{
				name: 'keywords',
				content: 'Flesch-Index, Lesbarkeitsindex, Reading-Ease"',
			},
			{
				property: 'og:title',
				content: 'Formel',
			},
			{
				property: 'og:description',
				content: 'Erläuterungen zur Flesch-Index Berechnung',
			},
			{
				property: 'og:image',
				content: 'https://fleschindex.de/assets/img/fleschindex.jpg',
			},
			{
				property: 'og:url',
				content: 'https://fleschindex.de/formel/',
			},
		]
	 },
	
    data () {
      return {
        desserts: [
          {
            wert: '0-30',
            lesbarkeit: 'Schwer',
          },
          {
            wert: '30-50',
            lesbarkeit: 'Schwierig',
          },
          {
            wert: '50-60',
            lesbarkeit: 'Anspruchsvoll',
          },
          {
            wert: '60-70',
            lesbarkeit: 'Normal',
          },
          {
            wert: '70-80',
            lesbarkeit: 'Einfach',
          },
          {
            wert: '80-90',
            lesbarkeit: 'Leicht',
          },
           {
            wert: '90-100',
            lesbarkeit: 'Sehr leicht',
          },
        ],
      }
    },
  
}

</script>
